import React, { ReactNode } from "react";
import "./button.css";
import { DownloadTableExcel } from "react-export-table-to-excel";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ReactToPrint from "react-to-print";
import { fontWeight } from "html2canvas/dist/types/css/property-descriptors/font-weight";

interface ButtonProps {
  children?: ReactNode;
  type: "close" | "download" | "save" | "update" | "fetch" | "delete" | "print" | "excel" | "pdf" | "doc" | "search" | "submit" | "edit" | "refresh";
  onClick?: (e:any) => any;
  loader?: boolean;
  fileName?: string;
  tableRef?: any;
  pdfId?: string;
  width?: string;
  height?: string;
  disabled?: boolean;
  hoverColor?: string;
  color?: string;
  fontSize?: string;
  orientation?: any;
  pageWidth?:number;
  pageHeights?:number;
  paperSize?:any;
}

const Button: React.FC<ButtonProps> = ({ children, disabled, type, onClick, loader, fileName, tableRef, pdfId, height = "auto", width = "auto", hoverColor, color, fontSize = "auto",orientation='p',pageWidth,pageHeights,paperSize='a4' }) => {
  const colors = {
    close: "#e74c3c", // Red
    download: "#3498db", // Blue
    save: "#2ecc71", // Green
    update: "#f1c40f", // Yellow
    fetch: "#9b59b6", // Purple
    delete: "#e74c3c", // Red
    print: "#16a085", // Teal
    excel: "#27ae60", // Dark Green
    pdf: "#c0392b", // Dark Red
    doc: "#2980b9", // Dark Blue
    search: "#f39c12", // Orange
    submit: "#1abc9c", // Teal for submission
    edit: "#f39c12", // Orange for editing
    refresh: "#3498db", // Blue for refreshing
  };

  const hoverColors = {
    close: "#cd6155", // Darker Red
    download: "#2980b9", // Darker Blue
    save: "#27ae60", // Darker Green
    update: "#f39c12", // Darker Yellow
    fetch: "#8e44ad", // Darker Purple
    delete: "#cd6155", // Darker Red
    print: "#1abc9c", // Darker Teal
    excel: "#2c3e50", // Very Dark Green
    pdf: "#8e44ad", // Very Dark Red
    doc: "#21618c", // Very Dark Blue
    search: "#d35400", // Darker Orange
    submit: "#148f77", // Dark Teal for submission
    edit: "#d35400", // Darker Orange for editing
    refresh: "#2c3e50", // Very Dark Blue for refreshing
  };

  const printDocument = (): void => {
    const input = document.getElementById(pdfId + "");

    if (!input) {
      return;
    }

    html2canvas(input, { scale: 2 }) // You can adjust the scale factor if needed
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        let imgWidth; // A4 width in mm
        let pageHeight; 
        if(orientation=='l' || orientation == 'landscape'){
          imgWidth =pageHeights || 297; // A4 width in mm
          pageHeight = pageWidth || 210; // A4 height in mm
        }else{
          imgWidth = pageWidth || 210; // A4 width in mm
          pageHeight =pageHeights || 297; // A4 height in mm
        }
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        const pdf = new jsPDF(orientation, "mm", paperSize);
        let position = 0;

        // Add initial image
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        // Loop to add images until we run out of content
        while (heightLeft > 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        pdf.save("download.pdf");
      })
      .catch((error) => {
        console.error("Could not generate PDF: ", error);
      });
  };

  const style = {
    backgroundColor: color ? color : disabled ? "gray" : colors[type],
    color: disabled ? "black" : "white",
    padding: "0.7vh 0.7vw",
    fontSize,
    fontWeight: "600",
    width,
    height,
    border: "none",
    borderRadius: "6px",
    margin: "5px",
    cursor: disabled ? "not-allowed" : "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    outline: "none",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    transition: "all 0.3s ease",
    "--first-Color": color ? color : disabled ? "gray" : colors[type],
    "--second-color": color ? color : disabled ? "gray" : colors[type],
  };

  return (
    <>
      {type === "excel" ? (
        <DownloadTableExcel filename={fileName || "file"} currentTableRef={tableRef}>
          <button
            style={style}
            className={loader ? "loader" : ""}
            onMouseOver={({ target }: any) => {
              // target.style.backgroundColor = hoverColors[type];
              target.style.transform = "translateY(-5px)";
            }}
            onMouseOut={({ target }: any) => {
              target.style.transform = "translateY(0px)";
              target.style.backgroundColor = color ? color : colors[type] || "#2c3e50";
            }}
          >
            {children}
          </button>
        </DownloadTableExcel>
      ) : type === "print" ? (
        <ReactToPrint
          documentTitle={fileName}
          trigger={() => (
            <button
              style={style}
              className={loader ? "loader" : ""}
              onMouseOver={({ target }: any) => {
                target.style.backgroundColor = hoverColor ? hoverColor : hoverColors[type];
                target.style.transform = "translateY(-5px)";
              }}
              onMouseOut={({ target }: any) => {
                target.style.transform = "translateY(0px)";
                target.style.backgroundColor = color ? color : colors[type] || "#2c3e50";
              }}
            >
              {children}
            </button>
          )}
          content={() => tableRef}
        />
      ) : (
        <div>
          <button style={style} disabled={disabled} onClick={!disabled && type === "pdf" ? () => printDocument() : onClick} className={loader ? "loader" : ""} onMouseOver={({ target }: any) => !disabled && ((target.style.transform = "translateY(-5px)"), (target.style.backgroundColor = hoverColor ? hoverColor : hoverColors[type]))} onMouseOut={({ target }: any) => !disabled && ((target.style.transform = "translateY(0px)"), (target.style.backgroundColor = color ? color : colors[type] || "#2c3e50"))}>
            {children}
          </button>
        </div>
      )}
    </>
  );
};

export default Button;
