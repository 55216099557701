import moment from 'moment';
import { getAxiosInstance, getAxiosTokenInstance } from '../../utils/axiosInstance';
import { useContext } from 'react';
import { FirebaseContext } from '../../context/FirebaseContext';
import Input from '../../NecttosComp/Input/Input';

export const getCollectedFeeL = (collegeId, skip, limit, search) => async () => {
	const instance = await getAxiosTokenInstance();

	try {
		const resp = await instance.get('/college/fees/getCollectedFees', {
			params: {
				collegeId,
				skip,
				limit,
				search,
			},
		});

		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getBookShort = (collegeId, state) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/library/getBookShort', {
			params: {
				collegeId,
				...state,
			},
		});

		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};
export const getMembers = (collegeId, search, userId) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		if (!userId) {
			throw new Error('select User First');
		}
		const resp = await instance.get('/college/library/getMembers', {
			params: {
				collegeId,
				userId,
				search,
			},
		});

		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getIssuedBook =
	({ collegeId, state, setLoading }) =>
	async () => {
		setLoading(true);
		const instance = await getAxiosTokenInstance();
		try {
			const resp = await instance.get('/college/library/getIssuedBook', {
				params: {
					collegeId,
					searchType: state?.searchType,
					searchValue: state?.searchValue,
					startDate: state?.startDate,
					endDate: state?.endDate,
					filterType: state?.filterType,
					filterValue: state?.filterValue,
					skip: state?.page * 30 || 0,
				},
			});

			if (resp.data.statusCode === 400) {
				setLoading(false);
				throw new Error(resp.data.message || 'API Error');
			} else {
				setLoading(false);
				return resp?.data;
			}
		} catch (error) {
			throw new Error(error?.response?.data?.message || error.message || 'API Error');
		}
	};
export const getRenewBook =
	({ collegeId, state, setLoading }) =>
	async () => {
		setLoading(true);
		const instance = await getAxiosTokenInstance();
		try {
			const resp = await instance.get('/college/library/getRenewBook', {
				params: {
					collegeId,
					searchType: state?.searchType,
					searchValue: state?.searchValue,
					startDate: state?.startDate,
					endDate: state?.endDate,
					filterType: state?.filterType,
					filterValue: state?.filterValue,
					skip: state?.page * 30 || 0,
				},
			});

			if (resp.data.statusCode === 400) {
				setLoading(false);
				throw new Error(resp.data.message || 'API Error');
			} else {
				setLoading(false);
				return resp?.data;
			}
		} catch (error) {
			setLoading(false);
			throw new Error(error?.response?.data?.message || error.message || 'API Error');
		}
	};
export const getActiveBook =
	({ collegeId, state, setLoading }) =>
	async () => {
		setLoading(true);
		const instance = await getAxiosTokenInstance();
		try {
			const resp = await instance.get('/college/library/getActiveBook', {
				params: {
					collegeId,
					searchType: state?.searchType,
					searchValue: state?.searchValue,
					startDate: state?.startDate,
					endDate: state?.endDate,
					filterType: state?.filterType,
					filterValue: state?.filterValue,
					skip: state?.page * 30 || 0,
				},
			});

			if (resp.data.statusCode === 400) {
				setLoading(false);
				throw new Error(resp.data.message || 'API Error');
			} else {
				setLoading(false);
				return resp?.data;
			}
		} catch (error) {
			setLoading(false);
			throw new Error(error?.response?.data?.message || error.message || 'API Error');
		}
	};
export const getBookWithMember = (collegeId, bookStocksId, editedDate) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		if (bookStocksId) {
			const resp = await instance.get('/college/library/getBookWithMember', {
				params: {
					collegeId,
					bookStocksId,
					editedDate,
				},
			});

			if (resp.data.statusCode === 400) {
				throw new Error(resp.data.message || 'API Error');
			} else {
				return resp?.data;
			}
		} else {
			throw new Error('Please Select Book');
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getReturnBook =
	({ collegeId, state, setLoading }) =>
	async () => {
		setLoading(true);
		const instance = await getAxiosTokenInstance();
		try {
			const resp = await instance.get('/college/library/getReturnBook', {
				params: {
					collegeId,
					searchType: state?.searchType,
					searchValue: state?.searchValue,
					startDate: state?.startDate,
					endDate: state?.endDate,
					filterType: state?.filterType,
					filterValue: state?.filterValue,
					skip: state?.page * 30 || 0,
				},
			});

			if (resp.data.statusCode === 400) {
				setLoading(false);
				throw new Error(resp.data.message || 'API Error');
			} else {
				setLoading(false);
				return resp?.data;
			}
		} catch (error) {
			setLoading(false);
			throw new Error(error?.response?.data?.message || error.message || 'API Error');
		}
	};
export const getDDCSubjects = (collegeId) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/action/getAllDDCSubjects', {
			params: {
				collegeId,
			},
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};
export const getFootPrint = (collegeId, dateFrom, dateTo) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/action/getFootPrint', {
			params: {
				collegeId,
				dateFrom,
				dateTo,
			},
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getQuestionnair = (collegeId, type) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/iqac/getQuestionnair', {
			params: {
				collegeId,
				type,
			},
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getOpenCourse = (collegeId, type) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/openCourse/getOpenCourseDetails', {
			params: {
				collegeId,
				type,
			},
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getQuestionnairAnalysis = (collegeId, questionnaireId, departmentId, teacherId) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/iqac/getQuestionnairAnalysis', {
			params: {
				collegeId,
				questionnaireId,
				...(teacherId ? { departmentId: '' } : { departmentId }),
				teacherId,
			},
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};
export const getDepartments = (collegeId) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/iqac/getDepartments', {
			params: {
				collegeId,
			},
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getTeachers = (collegeId) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/iqac/getTeachers', {
			params: {
				collegeId,
			},
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getQuestionnairPercentageAnalysis = (collegeId, questionnaireId) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/iqac/getPercentageAnalysis', {
			params: {
				collegeId,
				questionnaireId,
			},
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getLibraryfineRegister = (collegeId) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/action/getLibraryfineRegister', {
			params: {
				collegeId,
			},
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getAllHolidays = (collegeId) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/action/getAllHolidays', {
			params: {
				collegeId,
			},
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getAllAccademics = (collegeId) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/action/getAllAccadamicHolidays', {
			params: {
				collegeId,
			},
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getVerfificationDataLoader = (collegeId, classId) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/action/getVerfificationDataLoader', {
			params: {
				collegeId,
				classId,
			},
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getAllMinor = (collegeId, userId, classId) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/action/getAllMinor', {
			params: {
				collegeId,
				userId,
				classId,
			},
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};
export const getSportAdmision = (collegeId, userId, classId) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/action/getAllMinor', {
			params: {
				collegeId,
				userId,
				classId,
			},
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getAllMinorsSelected = (collegeId, classId, semester) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/action/getAllMinorsSelected', {
			params: {
				collegeId,
				classId,
				semester,
			},
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getAllEnquiry = (collegeId, selectedClass, searchTerm, modeOfKey) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/admissionProcess/getEnquiry', {
			params: {
				collegeId,
				classId: selectedClass,
				searchTerm,
				modeOfKey,
			},
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getAllPhoto = (collegeId, selectedClass, searchTerm, modeOfKey, activeStatus) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/admissionProcess/getAllPhoto', {
			params: {
				collegeId,
				classId: selectedClass,
				searchTerm,
				modeOfKey,
				activeStatus,
			},
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getSeatReservation = (collegeId, selectedClass, searchTerm) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/admissionProcess/getSeatReservation', {
			params: {
				collegeId,
				classId: selectedClass,
				searchTerm,
			},
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getAutoIndex = (collegeId, showAdvance, selectedClass) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/admissionProcess/getAutoIndex', {
			params: {
				collegeId,
				showAdvance,
				classId: selectedClass,
			},
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getAutoIndexClassWise = (collegeId, selectedClass, searchTerm, catogory) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/admissionProcess/getAutoIndexClassWise', {
			params: {
				collegeId,
				classId: selectedClass,
				searchTerm,
				catogory,
			},
		});
		console.log({ resp });
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getRealTimeRankingFYUGP = (collegeId, subjectId, catogory, type) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/fyugp/getRealTimeRankingFYUGP', {
			params: {
				collegeId,
				subjectId,
				catogory,
				type,
			},
		});
		console.log({ resp });
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const get = (collegeId, selectedClass, searchTerm, catogory) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/admissionProcess/getAutoIndexClassWise', {
			params: {
				collegeId,
				classId: selectedClass,
				searchTerm,
				catogory,
			},
		});
		console.log({ resp });
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getAutoIndexApplications = (collegeId, selectedClass, searchTerm, unfinalysed) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/admissionProcess/getApplications', {
			params: {
				collegeId,
				classId: selectedClass,
				searchTerm,
				unfinalysed,
			},
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getAutoIndexApplicationsPlusTwo = (collegeId, selectedClass, searchTerm) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/admissionProcess/getMarkEntryMistakes', {
			params: {
				collegeId,
				classId: selectedClass,
				searchTerm,
			},
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getBookRecomentation = (collegeId) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/action/getBookRecomentation', {
			params: {
				collegeId,
			},
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};
export const getEResources = (collegeId) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/action/getEResources', {
			params: {
				collegeId,
			},
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getPenddingAdmission = (collegeId, classId, userId, key, startDate, endingDate, newClassId) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/admissionProcess/getPendingAdmission', {
			params: {
				collegeId,
				classId,
				userId,
				key,
				startDate,
				endingDate,
				newClassId,
			},
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getNextSequenceNumber = (collegeId) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/admissionProcess/getAdmisionFalseNumber', {
			params: { collegeId },
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};
export const getAdmissionRegister = (collegeId, startDate, endingDate, classId, filterKey,selectedBatch) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/admissionProcess/getAdmissionRegister', {
			params: { collegeId, startDate, classId, endingDate, filterKey,selectedBatch },
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};
export const getEGrandRegister = (collegeId, startDate, endingDate, classId, searchText, year) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/admissionProcess/getEGrandRegister', {
			params: {
				collegeId,
				startDate,
				classId,
				endingDate,
				searchText,
				year,
			},
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getAdmissionDetails = (collegeId) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/admissionProcess/getAdmissionDetails', {
			params: { collegeId },
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getAdmissionDetailsCollegeOnly = (collegeId, year, date) => async () => {
	const instance = getAxiosInstance();

	try {
		const resp = await instance.get('/college/admissionProcess/getAdmissionDetails', { params: { collegeId, year, date } });

		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		console.error('API error:', error);
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getStaticAlotment = (collegeId, year, date) => async () => {
	const instance = getAxiosInstance();

	try {
		const resp = await instance.get('/college/admissionProcess/getStaticAlotment', { params: { collegeId, year, date } });

		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		console.error('API error:', error);
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getHerberium = (collegeId, state, searchQuery) => async () => {
	const instance = getAxiosInstance();
	try {
		const resp = await instance.get('/college/garden/getMyHerbarium', {
			params: { collegeId, searchQuery, ...state, isWeb: true },
		});

		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		console.error('API error:', error);
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getCustomeCollectionWithCustomeKeyDrops = (collegeId, key, collection) => async () => {
	const instance = getAxiosInstance();
	try {
		const resp = await instance.get('/college/garden/getCustomeCollectionWithCustomeKeyDrops', { params: { collegeId, key, collection } });
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data.list;
		}
	} catch (error) {
		console.error('API error:', error);
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

// export const getAdmissionDetailsCollegeOnly = (collegeId) => async () => {
//     const instance = await getAxiosTokenInstance();

//     try {
//         const resp = await instance.get('/college/admissionProcess/getAdmissionDetails', { params: { collegeId } });
//         if (resp.data.statusCode === 400) {
//             throw new Error(resp.data.message || 'API Error');
//         } else { return resp?.data; }
//     } catch (error) {
//         throw new Error(error?.response?.data?.message || error.message || 'API Error');
//     }
// }

export const getSplitups = (collegeId, batch) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/admissionProcess/getSplitups', {
			params: { collegeId, batch },
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getNewClasses = (collegeId, uid) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/admissionProcess/getFirstYearAdmissionClasses', {
			params: { collegeId, uid },
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getRulesofIndex = (collegeId, classId) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/admissionProcess/getRulesOfIndex', {
			params: { collegeId, classId },
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data.list;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};
export const getOpenCourseDetails = (collegeId, userId, openCourseId) => async () => {
	const instance = await getAxiosTokenInstance();

	try {
		const resp = await instance.get('college/openCourse/getOpenCourseDetails', {
			params: { collegeId, userId, openCourseId },
		});

		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getAdmissionValidator = (collegeId, classId, userId, index, admType) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/admissionProcess/getAdmissionValidator', {
			params: { collegeId, classId, userId, index, admType },
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getIsCall = (collegeId, userId) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/admissionProcess/getIsCall', {
			params: { collegeId, userId },
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getAllPeriodicals = (collegeId, bookType) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/action/getAllPeriodicals', {
			params: { collegeId, bookType },
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};
export const getAllPeriodicalsEntry = (collegeId, bookItem) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/action/getAllPeriodicalsEntry', {
			params: { collegeId, bookItem },
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getMainAccounts = (collegeId) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/action/getMainAccounts', {
			params: {
				collegeId,
			},
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getNumbers = (collegeId) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/action/getNumbers', {
			params: {
				collegeId,
			},
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getSubAccounts = (collegeId) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/action/getSubAccounts', {
			params: {
				collegeId,
			},
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getElectionPost = (collegeId) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/action/getElectionPost', {
			params: {
				collegeId,
			},
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getScholarship = (collegeId) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/action/getScholarship', {
			params: {
				collegeId,
			},
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getStudentsWithDues = (collegeId, admisionNo, feeYear) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/action/getStudentsWithDues', {
			params: {
				collegeId,
				admisionNo,
				feeYear,
			},
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getCollectedFees = (collegeId, skip, limit, search) => async () => {
	const instance = await getAxiosTokenInstance();

	try {
		const resp = await instance.get('/college/fees/getCollectedFees', {
			params: {
				collegeId,
				skip,
				limit,
				search,
			},
		});

		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getStatistics = (data) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/action/getFieldDropDownsUsers', {
			params: { ...data },
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};
export const getLibraryStatistics = (data) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/action/getFieldDropDowns', {
			params: { ...data },
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};
export const getCaste = (data) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.post('/college/action/getFieldDropDownsCaste', {
			...data,
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};
export const getCatogory = (data) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.post('/college/action/getFieldDropDownsCategory', {
			...data,
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getAditionalStatiData = (data) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.post('/college/action/getAditionalStatiData', {
			...data,
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};


export const getSeletedDetails = (data) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.post('/college/action/getSeletedDetails', {
			...data,
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getStatiCount = (data) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.post('/college/action/getStatiCount', {
			...data,
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getLibraryCount = (data) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.post('/college/action/getLibraryCount', {
			...data,
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getFeeDueList = (collegeId, skip, limit, search, noNeedTotals, classId) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/fees/getDues', {
			params: {
				collegeId,
				skip,
				limit,
				search,
				noNeedTotals,
				classId,
			},
		});

		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getFeeStructure = (collegeId, classId) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/action/getFeeStructure', {
			params: {
				collegeId,
				classId,
			},
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const collectFee = async ({ collegeId, userId, data, setIsLoading, setAlert }) => {
	const instance = await getAxiosTokenInstance();

	try {
		const resp = await instance.post('/college/fees/collectFee', data, {
			params: { collegeId, userId },
		});

		if (resp?.data?.statusCode === 400) {
			throw new Error(resp?.data?.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		throw new Error(
			setIsLoading(false),
			setAlert({
				type: 'Guide Line',
				text: error?.response?.data?.message || error?.message || 'API Error',
				show: true,
			}),
			error?.response?.data?.message || error?.message || 'API Error',
		);
	}
};

export const refundFee = async ({ collegeId, userId, collectionId, data }) => {
	const instance = await getAxiosTokenInstance();

	try {
		const resp = await instance.post('/college/fees/refundFee', data, {
			params: { collegeId, userId, collectionId },
		});

		if (resp?.data?.statusCode === 400) {
			throw new Error(resp?.data?.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error?.message || 'API Error');
	}
};

export const getFastCashTokens = (collegeId) => async () => {
	const instance = await getAxiosTokenInstance();

	try {
		const resp = await instance.get('/college/fastCash/getTokens', {
			params: {
				collegeId,
			},
		});

		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getDayBook = (collegeId, date) => async () => {
	const instance = await getAxiosTokenInstance();

	try {
		const resp = await instance.get('/college/fees/getDayBook', {
			params: {
				collegeId,
				date,
			},
		});

		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getReports = (params) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/fees/getReports', {
			params: params,
		});

		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};
export const getReportsSum = (params) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/fees/getReportsSum', {
			params: params,
		});

		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getDCBReports = (params) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.post('/college/fees/getDCBReports', {
			...params,
		});

		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getCashBook = (collegeId, date, type, subType, endDate) => async () => {
	const instance = await getAxiosTokenInstance();

	try {
		const resp = await instance.get('/college/fees/getCashBook', {
			params: {
				collegeId,
				date,
				type,
				subType,
				endDate,
			},
		});

		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getIncomeExpenditure = (collegeId, date, type, subType, endDate, accoundMode) => async () => {
	const instance = await getAxiosTokenInstance();

	try {
		const resp = await instance.get('/college/fees/getIncomeExpenditure', {
			params: {
				collegeId,
				date,
				type,
				subType,
				endDate,
				accoundMode,
			},
		});

		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getRecieptPayments = (collegeId, date, type, subType, endDate) => async () => {
	const instance = await getAxiosTokenInstance();

	try {
		const resp = await instance.get('/college/fees/getRecieptPayments', {
			params: {
				collegeId,
				date,
				type,
				subType,
				endDate,
			},
		});

		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};
export const getUserListNew = async ({ queryKey }) => {
	const [_, { type, collegeId, classId }] = queryKey;
	try {
		const instance = await getAxiosTokenInstance();
		const resp = await instance.get(`/college/users/get?collegeId=${collegeId}${type === 'all' ? '' : `&type=${type}`}${classId ? `&classId=${classId}` : ''}`);
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		}
		return resp.data;
	} catch (err) {
		throw new Error(err || 'API Error');
	}
};

export const getAttendanceSingleData = (collegeId, startDate, endDate, userId, classId) => async () => {
	const instance = await getAxiosTokenInstance();

	try {
		const resp = await instance.get('/college/attendance/report/attendanceRegisterSingleUser', {
			params: {
				collegeId,
				userId,
				startDate,
				endDate,
				classId,
			},
		});

		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp.data.classAttendanceList;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getAttendanceData = (collegeId, startDate, endDate, userId, classId) => async () => {
	const instance = await getAxiosTokenInstance();

	try {
		const resp = await instance.get('/college/attendance/report/attendanceRegister', {
			params: {
				collegeId,
				userId,
				startDate,
				endDate,
				classId,
			},
		});

		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp.data.classAttendanceList;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getMonthlyAttendanceData = (collegeId, startDate, endDate, userId, classId) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/attendance/report/attendanceMonthlyRegister', {
			params: {
				collegeId,
				userId,
				startDate,
				endDate,
				classId,
			},
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp.data.classAttendanceList;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getBookDetailsData = (stockId) => async () => {
	const instance = await getAxiosTokenInstance();

	try {
		const resp = await instance.get('/college/library/getBookDetails', {
			params: {
				stockId,
			},
		});

		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp.data.bookDetails;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const naacFetchData = async ({ data, url, setLoading, setValues }) => {
	try {
		const instance = await getAxiosTokenInstance();
		const val = await instance.post(url, data);
		setValues(val?.data?.list);
		setLoading(false);
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const naacExcelGenerator = async ({ data, url, setLoading }) => {
	setLoading(true);
	try {
		const link = document.createElement('a');
		link.target = '_blank';

		link.download = 'NAAC File' + moment(new Date()).format('DD_MM_YYYY HH MM');
		const instance = await getAxiosTokenInstance();

		const pdfData = await instance.post(
			url,
			{
				...data,
				needExcel: true,
			},
			{
				responseType: 'blob',
			},
		);

		setLoading(false);

		link.href = URL.createObjectURL(
			new Blob([pdfData.data], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			}),
		);
		link.click();
		setLoading(false);
	} catch (e) {
		throw new Error(e.message);
	}
};

export const AcademicYears = ({ setAcademicYear }) => {
	const today = new Date();
	const currentYear = today.getFullYear();
	const currentMonth = today.getMonth() + 1;

	// Determine the start year of the current academic year
	const startYear = currentMonth >= 8 ? currentYear : currentYear - 1;

	// Generate academic years for the past 8 years including the current one
	let academicYears = [];
	for (let year = startYear - 7; year <= startYear; year++) {
		academicYears.push(`${year}-${(year + 1 + '')?.slice(-2)}`);
	}

	academicYears = academicYears.reverse();

	return (
		// <select name="" id="" className="self-center m-2 text-sm w-44" onChange={(e) => setAcademicYear(e.target.value)}>
		//   {academicYears?.map((x) => (
		//     <option>{x}</option>
		//   ))}
		// </select>

		<Input fieldName='Select Year' type='drop' didntShowKey options={academicYears} onChange={(e) => setAcademicYear(e.value)} />
	);
};

export const EditDetails = async (val) => {
	const instance = await getAxiosTokenInstance();

	try {
		const resp = await instance.post('/college/profile/updateProfile', val);

		if (resp?.data?.statusCode === 400) {
			throw new Error(resp?.data?.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error?.message || 'API Error');
	}
};

export const getDropdownData = async () => {
	const instance = await getAxiosTokenInstance();
	const { data } = await instance.get('/dropDown/dropdownList');
	if (data.statusCode !== 200) {
		throw new Error(data.message || 'API Error');
	}
	let val = data?.dropdownList;
	return val;
};

export const initializePayment = async (data, pageInitialized, collegeId) => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.post('/payment/initialize', {
			...data,
			pageInitialized,
			collegeId,
		});
		if (resp?.status !== 200) {
			throw new Error(resp?.data?.message || 'API Error');
		} else {
			return resp?.data.redirectUrl;
		}
	} catch (error) {
		throw new Error(error?.message || 'API Error');
	}
};

export const initializePaymentRazorpay = async (data, collegeId, userId, accountId) => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.post('/razorpay/create-order', {
			...data,
			collegeId,
			userId,
			accountId,
		});
		if (resp?.status !== 200) {
			throw new Error(resp?.data?.message || 'API Error');
		} else {
			return resp?.data.order;
		}
	} catch (error) {
		throw new Error(error?.message || 'API Error');
	}
};

export const paymentStatus = async (RETURNMAC, hostedCheckoutId) => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/payment/status', {
			params: { RETURNMAC, hostedCheckoutId },
		});
		if (resp?.status !== 200) {
			throw new Error(resp?.data?.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		throw new Error(error?.message || 'API Error');
	}
};

export const getBatches = ({collegeId}) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/action/getBatches',{params: { collegeId}});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data.batches;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getKeys = () => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/action/getKeys');
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data.response[0].fields;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getAdmissionRegister3 = ({collegeId, startDate, endingDate, classId, filterKey,startAdmNo, endAdmNo,selectedBatch,tcStartDate,tcEndDate,startTcNo,endTcNo,advancedSearch}) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/admissionProcess/getAdmissionRegister3', {
			params: { collegeId, startDate, classId, endingDate,startAdmNo, endAdmNo, filterKey,selectedBatch,tcStartDate,tcEndDate,startTcNo,endTcNo,advancedSearch },
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getStudentAttendance1 = ({studentId,collegeId}) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp =  await instance.get('/college/attendance/report/studentAttendance', {
			params: { studentId, collegeId },
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getNextTcSequenceNumber = (collegeId) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/admissionProcess/getStatsNumber', {
			params: { collegeId },
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getAllMembers = (data) => async () => {
	let { setLoading } = data;
	setLoading(true);
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/transferCertificate/getTCList', {
			params: {
				...data,
			},
		});
		if (resp.data.statusCode === 400) {
			setLoading(false);
			throw new Error(resp.data.message || 'API Error');
		} else {
			setLoading(false);
			return resp?.data;
		}
	} catch (error) {
		setLoading(false);
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const fetchRtNo = async ({collegeId, start, end,startDate,endDate}) => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get(`/college/action/getRtNos`, {
			params: { collegeId, start,end,startDate,endDate },
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp.data
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getTCRequests = (data) => async () => {
	let { setLoading } = data;
	setLoading(true);
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/transferCertificate/getTCRequests', {
			params: {
				...data,
			},
		});
		if (resp.data.statusCode === 400) {
			setLoading(false);
			throw new Error(resp.data.message || 'API Error');
		} else {
			setLoading(false);
			return resp?.data;
		}
	} catch (error) {
		setLoading(false);
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};


export const getNextSequenceTcNumber = (collegeId) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/admissionProcess/getNextSequenceTcNumber', {
			params: { collegeId },
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};


export const getProfileData =
	({ studentId, collegeId }) =>
	async () => {
		const instance = await getAxiosTokenInstance();
		try {
			const resp = await instance.get('/college/transferCertificate/getProfileData', {
				params: { studentId, collegeId },
			});
			if (resp.data.statusCode === 400) {
				throw new Error(resp.data.message || 'API Error');
			} else {
				return resp?.data.profile;
			}
		} catch (error) {
			throw new Error(error?.response?.data?.message || error.message || 'API Error');
		}
	};

	export const getTcLists = (data) => async () => {
		let { setLoading } = data;
		setLoading(true);
		const instance = await getAxiosTokenInstance();
		try {
			const resp = await instance.get('/college/transferCertificate/getTCList', {
				params: {
					...data,
				},
			});
			if (resp.data.statusCode === 400) {
				setLoading(false);
				throw new Error(resp.data.message || 'API Error');
			} else {
				setLoading(false);
				return resp?.data;
			}
		} catch (error) {
			setLoading(false);
			throw new Error(error?.response?.data?.message || error.message || 'API Error');
		}
	};

	export const getBatchFull = ({collegeId}) => async () => {
		const instance = await getAxiosTokenInstance();
		try {
			const resp = await instance.get('/college/action/getBatchFull',{params: { collegeId}});
			if (resp.data.statusCode === 400) {
				throw new Error(resp.data.message || 'API Error');
			} else {
				return resp?.data.batches;
			}
		} catch (error) {
			throw new Error(error?.response?.data?.message || error.message || 'API Error');
		}
	};